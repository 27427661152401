import React from 'react'
import XLSX from 'xlsx'
import styled from 'styled-components'

const Label = styled.label`
  margin: 0;
  cursor: pointer;
`

const Input = styled.input`
  display: none;
`

const ExcelParser = ({ title, onExcelImport }) => {
	const handleFile = (file) => {
		const reader = new FileReader()
		const dataType = !!reader.readAsBinaryString
		reader.onload = (e) => {
			const binaryString = e.target.result
			const workbook = XLSX.read(binaryString, { type: dataType ? 'binary' : 'array' })
			const worksheetName = workbook.SheetNames[0]
			const worksheet = workbook.Sheets[worksheetName]
			const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

			if (onExcelImport) {
				onExcelImport(data)
			}
		}

		if(dataType) {
			reader.readAsBinaryString(file)
		} else {
			reader.readAsArrayBuffer(file)
		}
	}

	const suppress = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

	const handleDrop = (e) => {
    e.stopPropagation()
    e.preventDefault()

		const files = e.dataTransfer.files
		if(files && files[0]) handleFile(files[0])
	}

	const handleChange = (e) => {
		const files = e.target.files
		if(files && files[0]) handleFile(files[0])
	}

	return (
		<div 
			onDrop={handleDrop} 
			onDragEnter={suppress} 
			onDragOver={suppress}
		>
      <Label
        htmlFor="file"
        className='btn btn-outline-success'
      >
        {title}
      </Label>
      <Input
        id="file"
        type="file"
        accept='.xls,.xlsx'
        onChange={handleChange}
      />
		</div>
	);
}

export default ExcelParser
