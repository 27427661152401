import store from '../redux/store'
import { portsFetched } from '../redux/actions/ports'
import { PORTS_PATH } from '../config/urls'
import { getData } from './simple-request'
import { orderBy } from 'lodash'

export async function fetchPorts() {
  const url = PORTS_PATH
  const ports = await getData(url);

  if (ports) {
    store.dispatch(portsFetched(orderBy(ports, ['name'])));
  }
  return ports
}