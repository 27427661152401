import Request from './Request/Request'
import { notification } from 'antd'

const errorNotification = () => {
  notification.error({
    message: 'An error occurred',
  })
}

export const postData = async (url, body = {}) => {
  return new Request(url, { 
    authHeaders: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
   })
  .perform()
  .then(response => {
    return response
  })
  .catch(err => {
    errorNotification()
    return null
  })
}

export const getData = async url => {
  return new Request(url, { 
    authHeaders: true,
  })
  .perform()
  .then(response => {
    return response
  })
  .catch(err => {
    errorNotification()
    return null
  })
}

export const postImage = async (url, body = {}) => {
  return new Request(url, { 
    authHeaders: true,
    method: 'POST',
    body,
   })
  .perform()
  .then(response => {
    return response
  })
  .catch(err => {
    errorNotification()
    return null
  })
} 
