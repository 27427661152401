import store from '../redux/store'
import { productsFetched, productsUpserted } from '../redux/actions/products'
import { PRODUCTS_PATH, UPLOAD_PATH } from '../config/urls'
import { getData, postData, postImage } from './simple-request'

export const fetchProducts = async quoteId => {
  const url = `${PRODUCTS_PATH}/${quoteId}`
  const products = await getData(url);

  if (products) {
    store.dispatch(productsFetched(products));
  }
  return products
}

export const upsertProducts = async (quoteId, data) => {
  const url = `${PRODUCTS_PATH}/${quoteId}`
  const products = await postData(url, { products: data });
  
  if (products) {
    store.dispatch(productsUpserted(products));
  }

  return products
}

export const uploadImage = async (data) => {
  const photo = await postImage(UPLOAD_PATH, data )
  return photo
}
