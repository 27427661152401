import * as actions from '../actions/selected-quote'

export default function(state = null, action) {
  switch (action.type) {
    case actions.QUOTE_FETCHED: {
      return action.payload
    }
    case actions.QUOTE_SUBMITTED: {
      return action.payload
    }
    default:
      return state;
  }
}
