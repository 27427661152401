import React from 'react'
import styled from 'styled-components'

import Uploader from './Uploader'
import Deleter from './Deleter'
import Carousel from './Carousel'

const Container = styled.div`
  position: relative;
  width: 200px;
  &:hover .actions {
    display: flex;
    justify-content: space-evenly;
  }
`

const Actions = styled.div`
  width: 60px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  display: none;
`

const Caption = styled.p`
  margin: 0;
  font-size: 14px;
  text-align: center;
`

const ImageHandler = ({ images, quoteSubmitted, onUpload, onDelete }) => {
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0)
  const handleSlideChange = (imageIndex) => {
    setCurrentImageIndex(imageIndex)
  }

  const handleImageDelete = () => {
    if (onDelete) {
      onDelete(images[currentImageIndex])
    }
  }

  if (!images || !images.length) {
    return (
      <Container>
        <Uploader hasImages={false} onUpload={onUpload} />
      </Container>
    )
  }

  if (quoteSubmitted) {
    return (
      <Container>
        {images.length ? <Carousel images={images} /> : <i className="fas fa-camera pointer"></i>}
      </Container>
    )
  }

  const imageCount = images.length
  return (
    <Container>
      <Actions className='actions'>
        <Uploader hasImages onUpload={onUpload} />
        <Deleter onDelete={handleImageDelete} />
      </Actions>
      <Carousel images={images} onChange={handleSlideChange} />
      <Caption>{`${imageCount} image${imageCount > 1 ? 's' : ''} uploaded`}</Caption>
    </Container>
  )
}

export default ImageHandler
