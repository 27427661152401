import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { createQuote } from '../../api/quotes'
import { fetchPorts } from '../../api/fetchPorts'
import { fetchPaymentTerms } from '../../api/fetchPaymentTerms'
import { Input, Select } from 'antd'
import styled from 'styled-components'
import { get } from 'lodash'

const AddQuoteWizard = props => {
  const ports = get(props, 'ports') || []
  const paymentTerms = get(props, 'paymentTerms') || []
  const currentUser = get(props, 'currentUser') || {}

  const [selectedPort, setSelectedPort] = useState('')
  const [quoteName, setQuoteName] = useState('')
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState('')
  const [validUntilDate, setValidUntilDate] = useState('')
  const isSaveQuoteDisabled = !quoteName || !selectedPort || !selectedPaymentTerm || !validUntilDate

  useEffect(() => {
    fetchPorts()
    fetchPaymentTerms()
  }, [])

  const saveQuote = async () => {
    const { history } = props

    const quote = {
      name: quoteName,
      portId: selectedPort,
      paymentTermId: selectedPaymentTerm,
      validUntil: validUntilDate,
    }
    const createdQuote = await createQuote(quote)

    history.push(`/products/${createdQuote.id}`)
  }

  const renderAddQuoteInstructions = () => {
    return (
      <div className={'instructions'} style={{ width: '50%' }}>
        <div className={'flexrow'} >
          <div className={'flexcolumn'}>
            <span className={'step'}>Step 1</span><br />
              ADD NEW QUOTE <br />
              Enter a Name for the quote <br />
              Select FOB Port  <br />
              Select Payment Terms <br />
              Select date that the quote is Valid to  <br />
              ADD QUOTE<br />
            </div>
          <div className={'flexcolumn'}>
            <span className={'step'}>步骤一</span><br />
            输入：新报价 <br />
            输入报价名称 <br />
            选择离岸港口 <br />
            选择付款条件 <br />
            选择报价有效的日期 <br />
            输入：报价 <br />
          </div>
        </div>
      </div>
    )
  }

  const renderAddQuote = (modalTitle) => {
    return (
      <React.Fragment>
        <h4>{modalTitle || 'Add New Quote 输入：新报价 '}</h4>
        <Divider/>

        <div className={'flexrow'} style={{ marginBottom: '3px'}}>
          <div className={'flexcolumn, flex3'}><ModalText>Range or Quote Name</ModalText></div>
          <div className={'flexcolumn, flex10'}><StyledInputText style={{width: '98%'}} onChange={e => setQuoteName(e.target.value)} value={quoteName} /></div>
          <div className={'flexcolumn, flex3'}><ModalText>输入报价名称</ModalText></div>
        </div>
        <div className={'flexrow'} style={{ marginBottom: '3px'}}>
        <div className={'flexcolumn, flex3'}><ModalText>FOB Export Port</ModalText></div>
          <div className={'flexcolumn, flex10'}>
            <StyledSelect onChange={value => setSelectedPort(value)} size="large">
              {getPortsOptions(ports)}
            </StyledSelect>
          </div>
          <div className={'flexcolumn, flex3'}><ModalText>选择离岸港口</ModalText></div>
        </div>
        <div className={'flexrow'} style={{ marginBottom: '3px'}}>
          <div className={'flexcolumn, flex3'}><ModalText>Payment Terms</ModalText></div>
          <div className={'flexcolumn, flex10'}>
            <StyledSelect onChange={value => setSelectedPaymentTerm(value)} size="large">
              {getPaymentTermsOptions(paymentTerms)}
            </StyledSelect>
          </div>
          <div className={'flexcolumn, flex3'}><ModalText>选择付款条件</ModalText></div>
        </div>
        <div className={'flexrow'} style={{ marginBottom: '3px'}}>
          <div className={'flexcolumn, flex3'}><ModalText>Valid until</ModalText></div>
          <div className={'flexcolumn, flex10'}>
            <StyledInputText style={{width: '98%', height: '38px'}} type={'date'} onChange={e => setValidUntilDate(e.target.value)} value={validUntilDate} />
          </div>
          <div className={'flexcolumn, flex3'}><ModalText>选择报价有效的日期</ModalText></div>
        </div>
        <div className={'flexrow'} style={{ marginBottom: '3px'}}>
          <div className={'flexcolumn, flex3'}><ModalText>&nbsp;</ModalText></div>
          <div className={'flexcolumn, flex10'}>
            <input onClick={saveQuote} className="btn btn-outline-success" disabled={isSaveQuoteDisabled} type="button" value="Add quote 输入：报价" />
          </div>
          <div className={'flexcolumn, flex3'}><ModalText>&nbsp;</ModalText></div>
        </div>
      </React.Fragment>
    )
  }

  const getPaymentTermsOptions = terms => {
    const { Option } = Select
    return terms.map(term => (
      <Option value={term.id} key={term.id}>
        {term.name}
      </Option>
    ))
  }

  const getPortsOptions = ports => {
    const { Option } = Select
    return ports.map(port => (
      <Option value={port.id} key={port.id}>
        {port.name}
      </Option>
    ))
  }

  const currentSupplierName = currentUser && currentUser.supplier_legal_name
    ? `${currentUser.supplier_legal_name}` // [${currentUser.supplier_alias}]`
    : 'No supplier selected'

  return (
    <React.Fragment>
      <div>
        <br />
        <h2>Upload Quotes</h2>
        <h5>Supplier: <span className={'content'}>{currentSupplierName}</span></h5>
      </div>
      {renderAddQuoteInstructions()}
      <br />
      <div style={{border: '1px solid lightgray', padding: '20px', borderRadius: '3px', width: '90%'}}>
        {renderAddQuote()}
      </div>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    quotes: state.quotes,
    ports: state.ports,
    paymentTerms: state.paymentTerms,
    currentUser: state.currentUser,
  }
}

const ModalText = styled.span`
  display: inline-block;
  color: gray;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  width: 20%;
`

const Row = styled.div`
  display: block;
  padding: 5px;
`

const Divider = styled.div`
  background-color: #e9ecf6;
  height: 1px;
  margin-bottom: 15px;
`

const ModalBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5em;

  > * + * {
    margin-left: 0.5em;
  }
`

const StyledSelect = styled(Select)`
  width: 98%;
`

const StyledInputText = styled(Input)`
  height: 38px;
  width: 70%;
`

export default connect(mapStateToProps)(AddQuoteWizard)
