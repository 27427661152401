import * as actions from '../actions/user'

export default function(state = null, action) {
  switch (action.type) {
    case actions.USER_FETCHED: {
      return action.payload
    }
    default:
      return state;
  }
}
