import { BATTERIES_REQUIREMENT, PRODUCT_SHIP_MANNER } from '../../constants'

export default (worksheet, packagingTypes) => {
  worksheet.pageSetup.orientation = 'landscape'
  worksheet.pageSetup.fitToPage = true
  worksheet.properties.defaultRowHeight = 19

  worksheet.getRow(1).height = 29
  worksheet.getRow(2).height = 32.25
  worksheet.getRow(3).height = 25.5

  worksheet.columns = [
    { width: 8.17 }, // A
    { width: 19 }, // B
    { width: 13.83 }, // C
    { width: 20.33 }, // D
    { width: 20.33 }, // E
    { width: 20.33 }, // F
    { width: 24.33 }, // G
    { width: 9.83 }, // H
    { width: 18 }, // I
    { width: 9.66 }, // J
    { width: 6.33 }, // K
    { width: 6.33 }, // L
    { width: 10.33 }, // M
    { width: 10 }, // N
    { width: 10 }, // O
    { width: 12.33 }, // P
    { width: 21.5 }, // Q
    { width: 25.33 }, // R
    { width: 16.83 }, // S
    { width: 13.83 }, // T
    { width: 9.5 }, // U
    { width: 8.5 }, // V
    { width: 11.5 }, // W
    { width: 6.33 }, // X
    { width: 6.33 }, // Y
    { width: 7.83 }, // Z
  ]

  worksheet.mergeCells('F1:H1')
  const cellF1 = worksheet.getCell('F1')
  cellF1.value = '* Note All GREEN columns must be completed for items to be considered'
  cellF1.font = { size: 10 }
  cellF1.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
  cellF1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb:'009BBB59' } }

  const cellQ1 = worksheet.getCell('Q1')
  cellQ1.value = 'FOR ITEMS REQUIRING BATTERIES'
  cellQ1.font = { size: 10, bold: true }
  cellQ1.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
  cellQ1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb:'0000B0F1' } }

  worksheet.mergeCells('R1:S1')
  const cellR1 = worksheet.getCell('R1')
  cellR1.value = 'FOR FURNITURE FACTORIES ONLY'
  cellR1.font = { size: 10 }
  cellR1.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
  cellR1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb:'00A6A6A6' } }

  const charCodeOf = (char) => char.charCodeAt(0)

  for (let i = charCodeOf('B'); i <= charCodeOf('Z'); i++) {
    const col = String.fromCharCode(i)
    worksheet.mergeCells(`${col}2:${col}3`)
    const cell = worksheet.getCell(`${col}2`)
    cell.font = { name: 'Arial', size: 10, bold: true }
    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
    cell.fill = { type: 'pattern', pattern: 'solid' }
    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

    if (i >= charCodeOf('B') && i <= charCodeOf('P')) {
      cell.fill.fgColor = { argb: '009BBB59' }
    } else if (i === charCodeOf('Q')) {
      cell.fill.fgColor = { argb: '0000B0F1' }
    } else if (i === charCodeOf('R') || i === charCodeOf('S')) {
      cell.fill.fgColor = { argb: '00A6A6A6' }
    } else {
      cell.fill.fgColor = { argb: '00FEFF99' }
    }

    switch (col) {
      case 'B':
        cell.value = 'Item Description\n(Product Name)'
        break
      case 'C':
        cell.value = 'Supplier Article/Item Number'
        break
      case 'D':
        cell.value = 'Item Size\n(In-use dimensions)'
        break
      case 'E':
        cell.value = 'Colour'
        break
      case 'F':
        cell.value = 'Features'
        break
      case 'G':
        cell.value = 'Packaging Type'
        break
      case 'H':
        cell.value = 'MOQ'
        break
      case 'I':
        cell.value = 'Item Material Content'
        break
      case 'J':
        cell.value = 'FOB COST (USD)'
        break
      case 'K':
        cell.value = 'Units per Master'
        break
      case 'L':
        cell.value = 'Units per Inner'
        break
      case 'M':
        cell.value = 'Master Carton Length (cm)'
        break
      case 'N':
        cell.value = 'Master Carton Width (cm)'
        break
      case 'O':
        cell.value = 'Master Carton Height (cm)'
        break
      case 'P':
        cell.value = 'Master Carton G.W. (kg)'
        break
      case 'Q':
        cell.value = 'ARE THEY INCLUDED?'
        break
      case 'R':
        cell.value = 'DOES YOUR ITEM SHIP K/D OR FULLY ASSEMBLED?'
        break
      case 'S':
        cell.value = 'IF SHIPS K/D HOW MANY SCREWS REQUIRED FOR ASSEMBLY?'
        break
      case 'T':
        cell.value = 'Master Carton CBM'
        break
      case 'U':
        cell.value = 'Units per 20\''
        break
      case 'V':
        cell.value = 'Units per 40\''
        break
      case 'W':
        cell.value = 'Units per 40\' HQ'
        break
      case 'X':
        cell.value = 'Inner Length (cm)'
        break
      case 'Y':
        cell.value = 'Inner Width (cm)'
        break
      case 'Z':
        cell.value = 'Inner Height (cm)'
        break
      default:
        cell.value = ''
    }
  }

  for (let i = 1; i <= 200; i++) {
    const rowNo = i + 3
    worksheet.getRow(rowNo).height = 19

    const cellA = worksheet.getCell(`A${rowNo}`)
    cellA.value = `Product ${i}`
    cellA.font = { size: 8 }
    cellA.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
    cellA.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '00F2F2F2' } }

    const cellJ = worksheet.getCell(`J${rowNo}`)
    cellJ.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '00FDE9D9' } }

    for (let j = charCodeOf('B'); j <= charCodeOf('Z'); j++) {
      const cell = worksheet.getCell(`${String.fromCharCode(j)}${rowNo}`)
      cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    }

    const packagingTypeList = packagingTypes.map(item => item.name).join(',')
    worksheet.getCell(`G${rowNo}`).dataValidation = {
      type: 'list', 
      allowBlank: true,
      formulae: [`"${packagingTypeList}"`],
    }

    worksheet.getCell(`Q${rowNo}`).dataValidation = {
      type: 'list', 
      allowBlank: true,
      formulae: [`"${BATTERIES_REQUIREMENT.REQUIRED_AND_INCLUDED},${BATTERIES_REQUIREMENT.REQUIRED_BUT_NOT_INCLUDED}"`],
    }

    worksheet.getCell(`R${rowNo}`).dataValidation = {
      type: 'list', 
      allowBlank: true,
      formulae: [`"${PRODUCT_SHIP_MANNER.KD},${PRODUCT_SHIP_MANNER.FULLY_ASSEMBLED}"`],
    }

    worksheet.getCell(`T${rowNo}`).value = {
      formula: `IF(M${rowNo}*N${rowNo}*O${rowNo}/1000000=0,"",M${rowNo}*N${rowNo}*O${rowNo}/1000000)`,
      result: 0,
    }
  }
}
