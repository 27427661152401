import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Table, Modal, Input, Select, Button } from 'antd'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import styled from 'styled-components'

import { fetchQuotes, createQuote, copyQuote, cancelQuote } from '../../api/quotes'
import { fetchPorts } from '../../api/fetchPorts'
import { fetchPaymentTerms } from '../../api/fetchPaymentTerms'

const QuoteList = ({ quotes = [], ports = [], paymentTerms = [], currentUser = {}, currentSupplier = {}, history }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [originalQuoteId, setOriginalQuoteId] = useState(null)
  const [selectedPort, setSelectedPort] = useState('')
  const [quoteName, setQuoteName] = useState('')
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState('')
  const [defaultCompanyTermId, setDefaultCompanyTermId] = useState(null)
  const [validUntilDate, setValidUntilDate] = useState('')
  const isSaveQuoteDisabled = !quoteName || !selectedPort || !selectedPaymentTerm || !validUntilDate

  useEffect(() => {
    fetchQuotes()
    fetchPorts()
    fetchPaymentTerms()
  }, [])

  const openModal = () => {
    const defaultPaymentTerm = paymentTerms.find(item => item.code === 'tt_7')
    const defaultPaymentTermId = defaultPaymentTerm && defaultPaymentTerm.id || ''

    // check for company default term here
    let defaultCompanyTerm = null
    if (currentSupplier) {

      try {
        defaultCompanyTerm = currentSupplier &&
          currentSupplier.payment_method &&
          currentSupplier.payment_term_days &&
          paymentTerms.find(term => {
            return term.code === `${currentSupplier.payment_method.toLowerCase()}_${currentSupplier.payment_term_days}`
        })
      } catch (error) {
        console.log(' *** YYY.000 setSelectedPaymentTerm error', { currentSupplier, paymentTerms, error })
      }

      if (defaultCompanyTerm && defaultCompanyTerm.id) {
        setDefaultCompanyTermId(defaultCompanyTerm.id)
      }
    }
    // done

    setSelectedPaymentTerm(defaultCompanyTerm ? defaultCompanyTerm.id : defaultPaymentTermId)
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setSelectedPort('')
    setSelectedPaymentTerm('')
    setValidUntilDate('')
    setQuoteName('')
    setModalTitle('')
    setOriginalQuoteId(null)
  }

  const addNewQuote = () => {
    openModal()
  }

  const cancelQuoteModal = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this quote?')
    if (confirmed) {
      await cancelQuote(id)
      window.location.href = window.location.href
    }
  }

  const copyQuoteModal = (id, name) => {
    setModalTitle('Copy Quote')
    setOriginalQuoteId(id)
    setQuoteName(`Copy of ${name}`)
    openModal()
  }

  const createQuoteCopy = async () => {
    const copiedQuote = await copyQuote(originalQuoteId, quoteName, selectedPort, selectedPaymentTerm)
    closeModal()

    history.push(`/products/${copiedQuote.id}`)
  }

  const saveQuote = async () => {
    const quote = {
      name: quoteName,
      portId: selectedPort,
      paymentTermId: selectedPaymentTerm,
      validUntil: validUntilDate,
    }
    const createdQuote = await createQuote(quote)
    closeModal()

    history.push(`/products/${createdQuote.id}`)
  }

  const dataSource = (quotes) => {
    return quotes
      .filter(quote => quote.is_active)
      .map(quote => {
      return {
        key: get(quote, 'id'),
        id: get(quote, 'id'),
        title: `${get(quote, 'quote_number')}/${get(quote, 'version_number')}`,
        name: get(quote, 'name'),
        supplierName: get(quote, 'supplier.legal_name'),
        userName: get(quote, 'user') ? get(quote, 'user.full_name') : '-',
        portName: get(quote, 'port.name'),
        paymentTermName: get(quote, 'payment_term') 
          ? `${get(quote, 'payment_term.payment_type').toUpperCase()} ${get(quote, 'payment_term.days')} days`
          : '-',
        date: get(quote, 'created_at'),
        validUntil: get(quote, 'valid_until') ? get(quote, 'valid_until') : get(quote, 'created_at'),
        submitted_date: get(quote, 'submitted_date'),
        version: get(quote, 'version_number'),
        hasProducts: !isEmpty(get(quote, 'products')),
      }
    })
  }

  const redirectToProductPage = (quoteId) => {
    history.push(`/products/${quoteId}`)
  }

  const columns = () => {
    return [
      // {
      //   title: 'V',
      //   dataIndex: 'version',
      //   key: 'version',
      //   width: '20%',
      //   render: (text, row, index) => {
      //     return (
      //       <React.Fragment>
      //         v{text}
      //       </React.Fragment>
      //     )
      //   },
      // },
      {
        title: '',
        dataIndex: 'version',
        key: 'version',
        width: '15%',
        render: (text, row, index) => {
          return (
            <React.Fragment>
              <span onClick={() => {copyQuoteModal(row.id, row.name)}}><i className="fas fa-paste fa-2x"></i>&nbsp;</span>
            </React.Fragment>
          )
        },
      },
      {
        title: '',
        dataIndex: 'version',
        key: 'version',
        width: '15%',
        render: (text, row, index) => {
          return (
            <React.Fragment>
              <span onClick={() => {cancelQuoteModal(row.id, row.name)}}><i className="fas fa-trash fa-2x"></i>&nbsp;</span>
            </React.Fragment>
          )
        },
      },
      {
        title: 'Quote #',
        dataIndex: 'title',
        key: 'title',
        width: '15%',
        render: (text, row, index) => {
          return (
            <Button onClick={() => redirectToProductPage(row.key)} type="link">{text}</Button>
          )
        },
      },
      {
        title: 'Valid to',
        dataIndex: 'validUntil',
        key: 'validUntil',
        width: '15%',
        render: (text, row, index) => {
          if (!text) {
            return 
          }
          return (
            <Button onClick={() => redirectToProductPage(row.key)} type="link">{moment(text).format('DD MMM YY')}</Button>
          )
        },
      },
      {
        title: 'Quote',
        dataIndex: 'name',
        key: 'name',
        render: (text, row, index) => {
          return (
            <Button onClick={() => redirectToProductPage(row.key)} type="link">{text}</Button>
          )
        },
      },
      {
        title: 'Supplier',
        dataIndex: 'supplierName',
        key: 'supplierName',
        width: '15%',
        render: (text, row, index) => {
          return (
            <Button onClick={() => redirectToProductPage(row.key)} type="link">{text}</Button>
          )
        },
      },
      {
        title: 'User',
        dataIndex: 'userName',
        key: 'userName',
        width: '15%',
        render: (text, row, index) => {
          return (
            <Button onClick={() => redirectToProductPage(row.key)} type="link">{text}</Button>
          )
        },
      },
      {
        title: 'Port',
        dataIndex: 'portName',
        key: 'portName',
        width: '10%',
        render: (text, row, index) => {
          return (
            <Button onClick={() => redirectToProductPage(row.key)} type="link">{text}</Button>
          )
        },
      },
      {
        title: 'Terms',
        dataIndex: 'paymentTermName',
        key: 'paymentTermName',
        width: '10%',
        render: (text, row, index) => {
          return (
            <Button 
              onClick={() => redirectToProductPage(row.key)} 
              type="link">
                {text}
            </Button>
          )
        },
      },
      {
        title: 'Submitted',
        dataIndex: 'submitted',
        key: 'submitted',
        width: '25%',
        render: (text, row, index) => {
          const hasSubmitted = !isEmpty(row.submitted_date)
          if (hasSubmitted) {
            return (<span>Y</span>)
          }
          return (<span>N</span>)
        },
      },
      
    ]
  }

  const modal = () => {
    return (
    <Modal
      visible={modalOpen}
      title={false}
      footer={false}
      closable={false}
      width="60.3125rem"
      onCancel={closeModal}
    >
      <h4>
        {modalTitle || 'Add New Quote'}
      </h4>
      <Divider/>
      <Row>
        <ModalText>Range or Quote Name</ModalText>
        <StyledInputText onChange={e => setQuoteName(e.target.value)} value={quoteName} />
      </Row>
      <Row>
        <ModalText>FOB Export Port</ModalText>
        <StyledSelect onChange={value => setSelectedPort(value)} size="large">
          {getPortsOptions(ports)}
        </StyledSelect>
      </Row>
      <Row>
        <ModalText>Payment Terms</ModalText>
        <StyledSelect value={selectedPaymentTerm} onChange={value => setSelectedPaymentTerm(value)} size="large">
          {getPaymentTermsOptions(paymentTerms)}
        </StyledSelect>
      </Row>
      <Row>
        <ModalText>Valid until</ModalText>
        <StyledInputText type={'date'} onChange={e => setValidUntilDate(e.target.value)} value={validUntilDate} />
      </Row>
      <ModalBottom>
        <StyledButton
          value="Cancel"
          onClick={closeModal}
        />
        <StyledButton
          value="Add quote"
          disabled={isSaveQuoteDisabled}
          onClick={modalTitle === 'Copy Quote' ? createQuoteCopy : saveQuote}
        />
      </ModalBottom>
    </Modal>
    )
  }

  const getPaymentTermsOptions = terms => {
    const { Option } = Select
    return terms.map(term => (
      <Option value={term.id} key={term.id}>
        {term.name} {term.id === defaultCompanyTermId ? `- default term for ${currentUser.supplier_legal_name}` : ''}
      </Option>
    ))
  }

  const getPortsOptions = ports => {
    const { Option } = Select
    return ports.map(port => (
      <Option value={port.id} key={port.id}>
        {port.name}
      </Option>
    ))
  }

  const currentSupplierName = currentUser && currentUser.supplier_legal_name
    ? `${currentUser.supplier_legal_name}` // [${currentUser.supplier_alias}]`
    : 'No supplier selected'

  return (
    <React.Fragment>
    {modal()}
    <div>
        <br />
        <h2>Quote List</h2>
        <h5>Supplier: <span className={'content'}>{currentSupplierName}</span></h5>
        {!isEmpty(quotes) && (
          <div className={'quotes-table'}>
            <Table data-testid="quote-table" dataSource={dataSource(quotes)} columns={columns()} pagination={false} />
          </div>
        )} 
        <br />
        {currentUser && currentUser.supplier_legal_name && (
          <StyledButton
            data-testid="new-quote"
            value="Add new quote"
            onClick={addNewQuote}
          />
        )}
      </div>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  const currentSupplier = state.currentSupplier

  return {
    quotes: state.quotes,
    ports: state.ports,
    paymentTerms: state.paymentTerms,
    currentUser: state.currentUser,
    currentSupplier,
  }
}

const ModalText = styled.span`
  display: inline-block;
  color: gray;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  width: 20%;
`

const Row = styled.div`
  display: block;
  padding: 5px;
`

const Divider = styled.div`
  background-color: #e9ecf6;
  height: 1px;
  margin-bottom: 15px;
`

const ModalBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5em;

  > * + * {
    margin-left: 0.5em;
  }
`

const StyledButton = styled.input.attrs(() => ({
  className: 'btn btn-outline-success',
  type: 'button',
}))``

const StyledSelect = styled(Select)`
  width: 70%;
`

const StyledInputText = styled(Input)`
  height: 38px;
  width: 70%;
`

export default connect(mapStateToProps)(QuoteList)
