import React from 'react'

export default props => {
  const { value } = props
  const isValid = value ? Number(value) >= 0 : true // having no value is valid
  const formatted = value ? `USD ${Number(value).toFixed(3)}` : ''

  if (!isValid) {
    return (
      <span className={'validation-error-box'}>
        {formatted}&nbsp;
        <span className={'validation-reason'}>(FOB cannot be a negative number)</span>
      </span>
    )
  }

  return (
    <span>{formatted}</span>
  )
}

