import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { getCurrentUser } from '../../api/current-user'
import { getCurrentSupplier } from '../../api/fetchCurrentSupplier'
import { get } from 'lodash'

const UserName = props => {
  const currentUser = get(props, 'currentUser') || {}

  useEffect(() => {
    getCurrentUser()
    getCurrentSupplier()
  }, []);

  return (
    <div>
      {currentUser && currentUser.full_name} | {currentUser && currentUser.supplier_legal_name}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps)(UserName)
