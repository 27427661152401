import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { get } from 'lodash'
import styled from 'styled-components';
import { Input, notification } from 'antd'
import { fetchInvite, acceptInvite } from '../../api/invite'

const AcceptInvitation = props => {
    useEffect(() => {
        const { code } = props.match.params
        fetchInvite(code)
    }, [props.match.params]);
    
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const isAcceptDisabled = !password || !confirmPassword || (password !== confirmPassword)

    const validatePasswordComplexity = (pwd) => {
      const specials = Array.from('~`!@#$%^&*()_-+={}[]|:;"<>?,./')
      // console.log(specials)

      if (pwd.length < 8) {
        notifyError("Password must be at least 8 characters long");
        return false
      } else if (pwd.search(/\d/) === -1) {
        notifyError("Password must contain at least one number");
        return false
      } else if (pwd.search(/[a-zA-Z]/) === -1) {
        notifyError("Password must contain at least one letter");
        return false
      }
      else {
        const hasSpecial = specials.find(special => pwd.indexOf(special) > -1)
        if (!hasSpecial) {
          notifyError("Password must contain at least one special character from ~`!@#$%^&*()_-+={}[]|:;\"<>?,./");
          return false
        }
      }
      return true
    }

    const notifyError = (msg) => {
      notification.open({
        type: 'error',
        message: msg,
      })
    }

    const accept = async () => {
      if (!validatePasswordComplexity(password)) {
        return
      }

      const userId = get(props, 'invite.id')
      const accepted = await acceptInvite(userId, password)
      if (!accepted) {
        notification.open({
          type: 'error',
          message: 'Invalid password',
        });
      }
    }

    const invite = get(props, 'invite')
    const hasAccepted =  get(props, 'invite.accepted') || false

    console.log(' **** INVITE: ', { invite })

    if (hasAccepted === true) {
      window.location.href = '/login'
    }

    const specials = '~`!@#$%^&*()_-+={}[]|:;"<>?,./'

    return (
        <div>
            <br />
            <h2>Accept Invitation</h2>
            { !invite &&
              <div>
                Invitation not found. Please contact your MACVAD sales manager for assistance.
              </div>
            }
            { invite &&
             <Container>
              <Row>
                <StyledLabel>Name: </StyledLabel>
                <StyledLabel>{invite && invite.full_name} </StyledLabel>
              </Row>
              <Row>
                <StyledLabel>Email: </StyledLabel>
                <StyledLabel>{invite && invite.email} </StyledLabel>
              </Row>
              <Row>
                <StyledLabel>Password: </StyledLabel>
                <StyledInputText type='password' onChange={e => setPassword(e.target.value)} value={password} />
              </Row>
              <Row>
                <StyledLabel>Confirm Password: </StyledLabel>
                <StyledInputText type='password' onChange={e => setConfirmPassword(e.target.value)} value={confirmPassword} />
              </Row>

              <br />
              <div>
                <p>&nbsp;&nbsp;&nbsp;Password must be 8 characters or more and must contain at least one number and one special character from <br /> {specials}. <br />Common passwords like "password1" are not allowed.</p>
              </div>

              <DashedLine className="line"></DashedLine>
              <Row>
                  <input onClick={accept} className="btn btn-outline-success" disabled={isAcceptDisabled} type="button" value="Accept Invite" />
              </Row>
            </Container> }
        </div>
      )
}

function mapStateToProps(state) {
  return {
    invite: state.invite
  }
}

const Row = styled.div`
  display: block;
  padding: 5px;
`;

const Container = styled.div`
  display: block;
  padding: 5px;
  width: 70%;
`;

const DashedLine = styled.div`
  margin: 0px 0 20px 0;
  margin-top: 30px;
`;

const StyledLabel = styled.span`
  display: inline-block;
  color: gray;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  padding: 0px 10px 0px 5px;
  width:20%;
`;

const StyledInputText = styled(Input)`
  height: 38px;
  width: 50%;
`;

export default connect(mapStateToProps)(AcceptInvitation)
