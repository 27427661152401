export const PRODUCTS_FETCHED = "PRODUCTS_FETCHED"
export const PRODUCTS_UPSERTED = "PRODUCTS_UPSERTED"

export const productsFetched = (products) => ({
  type: PRODUCTS_FETCHED,
  payload: products,
})

export const productsUpserted = (products) => ({
  type: PRODUCTS_UPSERTED,
  payload: products,
})