// console.log('Choosing API URL', {h: process.env.HORIZON_API_URL, r: process.env.REACT_APP_API_URL })

const PORTAL_API_ROOT = process.env.HORIZON_API_URL || process.env.REACT_APP_API_URL

export const LOGIN_PATH = `${PORTAL_API_ROOT}/oauth/login`
export const AD_TOKEN_PATH = `${PORTAL_API_ROOT}/oauth/token`
export const REFRESH_TOKEN_PATH = `${PORTAL_API_ROOT}/oauth/refresh`

export const SUPPLIERS_PATH = `${PORTAL_API_ROOT}/suppliers`
export const QUOTES_PATH = `${PORTAL_API_ROOT}/quotes`
export const PORTS_PATH = `${PORTAL_API_ROOT}/ports`
export const USERS_PATH = `${PORTAL_API_ROOT}/users`
export const PRODUCTS_PATH =`${PORTAL_API_ROOT}/products`
export const UPLOAD_PATH =`${PORTAL_API_ROOT}/upload`
export const PACKAGINGS_PATH =`${PORTAL_API_ROOT}/packagings`
export const PAYMENT_TERMS_PATH = `${PORTAL_API_ROOT}/payment_terms`

// const allKeys = Object.keys(process.env)
// const appKeys = allKeys
//   .sort().filter(key =>
//       key.indexOf('npm_') === -1 
//       && key.indexOf('PATH') === -1
//       && key.indexOf('PASS') === -1
//     )

// const info = appKeys.map(key => `${key}=${key === 'DB_PASS' ? '***' : process.env[key]}`)

// console.log('[MACVAD Prices Portal UI] ENVIRONMENT variables:\r\n', appKeys.length, info.join('\r\n'))
