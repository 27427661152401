import { combineReducers } from "redux"
import quotes from "./quotes"
import selectedQuote from "./selected-quote"
import ports from "./ports"
import products from "./products"
import user from "./user"
import currentUser from "./current-user"
import currentSupplier from "./current-supplier"
import invite from "./invite"
import packagings from "./packagings"
import paymentTerms from "./payment-terms"

export default combineReducers({
    quotes,
    ports,
    products,
    selectedQuote,
    user,
    invite,
    currentUser,
    currentSupplier,
    packagings,
    paymentTerms,
})
