import store from '../redux/store'
import { SUPPLIERS_PATH } from '../config/urls'
import { currentSupplier } from '../redux/actions/current-supplier'
import { getData } from './simple-request'

export async function getCurrentSupplier() {
  const url = `${SUPPLIERS_PATH}/current`

  try {
    const supplier = await getData(url);
    if (supplier) {
      store.dispatch(currentSupplier(supplier));
    }

    return supplier
  } catch (err) {
    return null
  }
} 