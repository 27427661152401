import React from 'react'
import { Select } from 'antd'
import styled from 'styled-components';

export default props => {
  const buildOptions = options => {
    const { Option } = Select;
    return options.map(option => (
      <Option value={option.id} key={option.id}>
        {option.name}
      </Option>
    ));
  };

  if (props.quoteSubmitted) {
    return (
      <StyledSelect disabled defaultValue={props.defaultValue}>
        {buildOptions(props.options)}
      </StyledSelect>
    )
  }

  return (
    <StyledSelect
      id={`${props.columnName}${props.fieldName}`}
      defaultValue={props.defaultValue}
      placeholder={props.placeholder}
      size="large"
      onChange={value => props.handleOnChange(value, props.columnName, props.fieldName)}
    >
      {buildOptions(props.options)}
    </StyledSelect>
  )    
}

const StyledSelect = styled(Select)`
  width: 70%;
`;
