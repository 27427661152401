import * as actions from '../actions/current-user'

export default function(state = null, action) {
  switch (action.type) {
    case actions.CURRENT_USER: {
      return action.payload
    }
    default:
      return state;
  }
}
