export const QUOTE_FETCHED = "QUOTE_FETCHED"
export const QUOTE_SUBMITTED = "QUOTE_SUBMITTED"

export const quoteFetched = (quote) => ({
  type: QUOTE_FETCHED,
  payload: quote,
})

export const quoteSubmitted = (quote) => ({
  type: QUOTE_SUBMITTED,
  payload: quote,
})
