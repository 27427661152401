import React from 'react'

export default props => {
  const { allKeys, row, title, value } = props

  const thisKey = allKeys.find(item => item.key === row.key)
  const { decimalPlaces, suffix } = thisKey

  const isValid = value ? Number(value) >= 0 : true // having no value is valid
  const formatted = value ? Number(value).toFixed(decimalPlaces || 0) : ''

  if (!isValid) {
    return (
      <span className={'validation-error-box'}>
        {formatted}&nbsp;
        <span className={'validation-reason'}>({title} cannot be a negative number)</span>
      </span>
    )
  }

  return (
    <span>{formatted}&nbsp;{suffix || ''}</span>
  )
}

