import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Input } from 'antd'
import { login } from '../../api/user'
import { get } from 'lodash'

const SupplierLogin = props => {
    const [username, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const isLoginDisabled = !username || !password
    const [showInvalidUserMessage, setShowInvalidUserMessage] = useState(false)

    const accessToken = get(props, 'user.token')
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken)
      window.location.href = '/quotes'
    }

    const supplierLogin = async () => {
      const user = await login(username, password)
      if (!user) {
        setShowInvalidUserMessage(true)
        // console.log('showInvalidUserMessage', showInvalidUserMessage)
      }
    }
    
    return (
        <div>
          <br />
          <h2>Supplier Login</h2>
          <Container>
            <Row>
              <StyledInputText placeholder='Email address' onChange={e => setUserName(e.target.value)} value={username} />
            </Row>
            <Row>
              <StyledInputText type='password' placeholder='Password'
              onChange={event => setPassword(event.target.value)} 
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  supplierLogin()
                }
              }}
              value={password} />
            </Row>
            { showInvalidUserMessage && 
              <Row>
                <InvalidLogin>Invalid username or password. </InvalidLogin>
              </Row>
            }
            <Row>
              <StyledLoginButton onClick={supplierLogin} className="btn btn-outline-success" disabled={isLoginDisabled} type="button" value="Login" />
            </Row>
          </Container>
        </div>
      )
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

const Row = styled.div`
  display: block;
  padding: 5px;
`;

const Container = styled.div`
  display: block;
  width: 50%;
`;

const InvalidLogin = styled.span`
  display: inline-block;
  font-size: 18px;
  color: #f5222d;
  padding: 20px 0px 0px 0px;
`;

const StyledInputText = styled(Input)`
  height: 38px;
  width: 50%;
`;

const StyledLoginButton = styled.input`
  height: 38px;
  width: 50%;
`;

export default connect(mapStateToProps)(SupplierLogin)
