import { UnAuthorisedError } from '../../ExtendError/UnAuthorisedError'

// Thows and error if status is not in the 200 range
export default function handleResponseError(response, nextInterceptor) {
  if (response.status < 200 || response.status >= 300 || response instanceof Error) {
    let error
    if (response.status === 401) {
      localStorage.removeItem('accessToken')
      window.location.href = '/login'
      error = new UnAuthorisedError(`Error: ${response.status} Message: Unauthorized`)
    } else if (response instanceof Error) {
      error = response
    } else {
      error = new Error(`Error: ${response.status} Message: ${response.statusText}`)
    }
    error.response = response
    throw error
  }
  return nextInterceptor(response)
}
