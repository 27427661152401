export const USER_FETCHED = "USER_FETCHED"
export const CURRENT_USER = "CURRENT_USER"

export const userFetched = (user) => ({
  type: USER_FETCHED,
  payload: user,
})

export const currentUser = (user) => ({
  type: CURRENT_USER,
  payload: user,
})