export default function parseResponseToJson(response, nextInterceptor) {
  if (response.headers) {
    const contentType = response.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return nextInterceptor(response.json())
    }
    return nextInterceptor(response.text())
  }
  return nextInterceptor(response)
}
