import store from '../redux/store'
import { USERS_PATH } from '../config/urls'
import { inviteFetched, inviteAccepted } from '../redux/actions/invite'
import { postData, getData } from './simple-request'

export async function fetchInvite(code) {
  const url = `${USERS_PATH}/invite/${code}`
  const invite = await getData(url);

  console.log(' *** Fetching invite from API ', { code })
  if (invite) {
    store.dispatch(inviteFetched(invite))
  }

  console.log(' *** Invite data ', { code, invite })

  return invite
} 

export async function acceptInvite(userId, password) {
  const url = `${USERS_PATH}/acceptinvite/${userId}`

  try {
    const invite = await postData(url, { password })
    if (invite) {
      store.dispatch(inviteAccepted(invite))
    }
    return invite
  } catch (error) {
    return false
  }


}
