export const BATTERIES_REQUIREMENT = {
  REQUIRED_AND_INCLUDED: 'Required and Included',
  REQUIRED_BUT_NOT_INCLUDED: 'Required but not Included',
}

export const PRODUCT_SHIP_MANNER = {
  KD: 'K/D',
  FULLY_ASSEMBLED: 'Fully Assembled',
}

export const DEFAULT_MACVAD_GP = 0.2
