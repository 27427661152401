import store from '../redux/store'
import { packagingsFetched } from '../redux/actions/packaging'
import { PACKAGINGS_PATH } from '../config/urls'
import { getData } from './simple-request'

export async function fetchPackagings() {
  const packagings = await getData(PACKAGINGS_PATH);

  if (packagings) {
    store.dispatch(packagingsFetched(packagings));
  }
  return packagings
}