export const INVITE_FETCHED = "INVITE_FETCHED"
export const INVITE_ACCEPTED = "INVITE_ACCEPTED"

export const inviteFetched = (invite) => ({
  type: INVITE_FETCHED,
  payload: invite,
})

export const inviteAccepted = (invite) => ({
  type: INVITE_ACCEPTED,
  payload: invite,
})