import store from '../redux/store'
import { USERS_PATH } from '../config/urls'
import { userFetched } from '../redux/actions/user'
import { getData, postData } from './simple-request'

export async function login(username, password) {
  const url = `${USERS_PATH}/login`

  try {
    const user = await postData(url, { username, password });
    if (user) {
      store.dispatch(userFetched(user));
    }
    return user
  } catch (err) {
    return null
  }
} 

export async function getCurrentUser() {
  const url = `${USERS_PATH}/current`

  try {
    const user = await getData(url);
    if (user) {
      store.dispatch(userFetched(user));
    }
    return user
  } catch (err) {
    return null
  }
} 