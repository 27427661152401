import Excel from 'exceljs'
import FileSaver from 'file-saver'

import template from './template'

export default (packagingTypes) => {
  const workbook = new Excel.Workbook()

  const worksheet = workbook.addWorksheet('Products')
  template(worksheet, packagingTypes)

  workbook.xlsx.writeBuffer().then(function (result) {
    const blob = new Blob([result], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
    FileSaver.saveAs(blob, 'MACVAD Upload Template.xlsx')
  })
}
