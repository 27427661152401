import * as actions from '../actions/invite'

export default function(state = null, action) {
  switch (action.type) {
    case actions.INVITE_FETCHED: {
      return {...action.payload, accepted: false}
    }
    case actions.INVITE_ACCEPTED: {
      return {...action.payload, accepted: true}
    }
    default:
      return state;
  }
}
