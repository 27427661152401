import React from 'react'

const NotFound = props => {
  return (
    <div>
      <h5>Page Not Found!</h5>
    </div>
  )
}

export default NotFound
