import store from '../redux/store'
import { USERS_PATH } from '../config/urls'
import { currentUser } from '../redux/actions/current-user'
import { getData } from './simple-request'

export async function getCurrentUser() {
  const url = `${USERS_PATH}/current`

  try {
    const user = await getData(url);
    if (user) {
      store.dispatch(currentUser(user));
    }

    return user
  } catch (err) {
    return null
  }
} 