import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { get, chain, isEmpty } from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import { Modal, notification } from 'antd'

// APIs
import { upsertProducts, uploadImage } from '../../api/products'
import { fetchQuote, submitQuote, cancelQuote } from '../../api/quotes'
import { fetchPackagings } from '../../api/packagings'

// components
import { ProductEditableTable } from '../Product/ProductEditableTable'
import ImageHandler from '../Product/ImageHandler'
import BooleanContainer from '../Product/BooleanContainer'
import MultiSelectContainer from '../Product/MultiSelectContainer'
import FobCostContainer from '../Product/FobCostContainer'
import NumberContainer from '../Product/NumberContainer'
import ExcelParser from '../Product/ExcelParser'

// utils
import exportTemplate from '../../helpers/excelGenerator/macvad/generator'
import { BATTERIES_REQUIREMENT, PRODUCT_SHIP_MANNER, DEFAULT_MACVAD_GP } from '../../helpers/constants'

const AddProductWizard = props => {
  const [key, setKey] = useState('')
  const [productsToSave, setProductsToSave] = useState([])
  const [disableSave, setDisableSave] = useState(true)
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [isSubmittedQuote, setIsSubmittedQuote] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [step, setStep] = useState('downloadTemplate')
  const [deletedProducts, setDeletedProducts] = useState([])
  const [submitValidation, setSubmitValidation] = useState(null)

  const quote = get(props, 'quote')
  const packagings = get(props, 'packagings') || []

  // 05 MAY 24 - add new fields for CHEA and rearrange the order of fields to match the new spreadsheet
  const keys = [
    { key: 'id', title: 'id', type: 'text', },
    { key: 'images', title: 'Item Picture (very good quality, 5mb max size)', type: 'images', },
    { key: 'description', title: 'Description', type: 'text', },
    { key: 'item_no', title: 'Item Number', type: 'text', },
    { key: 'colour', title: 'Colour', type: 'text', optional: true },
    { key: 'features', title: 'Features', type: 'text', optional: true },
    { key: 'packaging_type', title: 'Packaging Type', type: 'text' }, //, type: 'packagings', },
    { key: 'moq', title: 'MOQ', type: 'number', optional: true },
    { key: 'material', title: 'Item Material', type: 'text', },
    { key: 'fob_cost', title: 'FOB USD NET', type: 'number' },
    { key: 'units_per_outer', title: 'Units per Outer Carton', type: 'number' },
    { key: 'outer_length', title: 'Carton Length (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm' },
    { key: 'outer_width', title: 'Carton Width (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm' },
    { key: 'outer_height', title: 'Carton Height (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm' },
    { key: 'outer_weight_kg', title: 'Outer Weight (kg)', type: 'number', decimalPlaces: 2, suffix: 'kg', optional: true },
    // { key: 'outer_dimensions', title: 'Outer Carton Dimensions (H W D) cm', type: 'label' },
    // { key: 'outer_cbm', title: 'Outer CBM', type: 'label' },
    { key: 'units_per_inner', title: 'Units per Inner Carton', type: 'number', optional: true },
    { key: 'inner_length', title: 'Inner Length (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm', optional: true },
    { key: 'inner_width', title: 'Inner Width (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm', optional: true },
    { key: 'inner_height', title: 'Inner Height (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm', optional: true },
    { key: 'inner_weight_kg', title: 'Inner Weight (kg)', type: 'number', decimalPlaces: 2, suffix: 'kg', optional: true },
    { key: 'retail_length', title: 'Retail Length (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm', optional: true },
    { key: 'retail_width', title: 'Retail Width (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm', optional: true },
    { key: 'retail_height', title: 'Retail Height (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm', optional: true },
    { key: 'retail_weight', title: 'Retail Weight (g)', type: 'number', decimalPlaces: 2, suffix: 'g', optional: true },
    { key: 'in_use_length', title: 'In Use Length (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm', optional: true },
    { key: 'in_use_width', title: 'In Use Width (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm', optional: true },
    { key: 'in_use_height', title: 'In Use Height (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm', optional: true },
    { key: 'product_capacity', title: 'Product Capacity', type: 'number', decimalPlaces: 2, optional: true },
    { key: 'product_capacity_uom', title: 'Product Capactity UOM', type: 'text', optional: true },
    { key: 'batteries', title: 'Batteries Required', type: 'boolean', },
    { key: 'assembled', title: 'Assembled Required', type: 'boolean', optional: true },
    { key: 'screws', title: 'Screws Count', type: 'number', optional: true},
    { key: 'outer_cbm', title: 'Master Carton CBM', type: 'label' },
    { key: 'qty_per_20', title: 'Qty per 20 ft Container', type: 'number', optional: true },
    { key: 'qty_per_40', title: 'Qty per 40 ft Container', type: 'number', optional: true },
    { key: 'qty_per_40_hq', title: 'Qty per 40 ft HQ Container', type: 'number', optional: true },
    // { key: 'weight_kg', title: 'Gross Weight (kg)', type: 'number', decimalPlaces: 2, suffix: 'kg' },
    { key: 'position', title: 'Position', type: 'number' },
    { key: 'is_active', title: 'Is Active', type: 'boolean' },
  ]

  // old 
//   const keys = [
//     { key: 'id', title: 'id', type: 'text', },
//     { key: 'images', title: 'Item Picture (very good quality, 5mb max size)', type: 'images', },
//     { key: 'item_no', title: 'Item Number', type: 'text', },
//     { key: 'description', title: 'Description', type: 'text', },
//     { key: 'size', title: 'Size', type: 'text', optional: true },
//     { key: 'colour', title: 'Colour', type: 'text', optional: true },
//     { key: 'features', title: 'Features', type: 'text', optional: true },
//     { key: 'packaging_type', title: 'Packaging Type', type: 'text' }, //, type: 'packagings', },
//     { key: 'moq', title: 'MOQ', type: 'number', optional: true },
//     { key: 'material', title: 'Item Material', type: 'text', },
//     { key: 'fob_cost', title: 'FOB USD NET', type: 'number' },
//     { key: 'units_per_outer', title: 'Units per Outer Carton', type: 'number' },
//     { key: 'units_per_inner', title: 'Units per Inner Carton', type: 'number', optional: true },
//     { key: 'batteries', title: 'Batteries Required', type: 'boolean', },
//     { key: 'battery_type', title: 'Battery Type', type: 'text', optional: true },
//     { key: 'battery_count', title: 'Battery Count', type: 'number', optional: true},
//     { key: 'assembled', title: 'Assembled Required', type: 'boolean', optional: true },
//     { key: 'screws', title: 'Screws Count', type: 'number', optional: true},
//     { key: 'outer_length', title: 'Carton Length (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm' },
//     { key: 'outer_width', title: 'Carton Width (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm' },
//     { key: 'outer_height', title: 'Carton Height (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm' },
//     { key: 'weight_kg', title: 'Gross Weight (kg)', type: 'number', decimalPlaces: 2, suffix: 'kg' },
//     { key: 'qty_per_20', title: 'Qty per 20 ft Container', type: 'number', optional: true },
//     { key: 'qty_per_40', title: 'Qty per 40 ft Container', type: 'number', optional: true },
//     { key: 'qty_per_40_hq', title: 'Qty per 40 ft HQ Container', type: 'number', optional: true },
//     { key: 'outer_dimensions', title: 'Outer Carton Dimensions (H W D) cm', type: 'label' },
//     { key: 'inner_length', title: 'Inner Length (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm', optional: true },
//     { key: 'inner_width', title: 'Inner Width (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm', optional: true },
//     { key: 'inner_height', title: 'Inner Height (cm)', type: 'number', decimalPlaces: 2, suffix: 'cm', optional: true },
// //    { key: 'outer_cbm', title: 'Outer CBM', type: 'text', optional: true, readOnly: true },
//     { key: 'outer_cbm', title: 'Outer CBM', type: 'label' },
//     { key: 'position', title: 'Position', type: 'number' },
//     { key: 'is_active', title: 'Is Active', type: 'boolean' },
//   ]

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    fetchPackagings()
  }, [])

  useEffect(() => {
    const { quoteId } = props.match.params
    fetchQuote(quoteId)
      .then(quote => {
        if (!quote) {
          window.location.href = '/'
        }
      
        const isSubmitted = quote && quote.submitted_date
        setIsSubmittedQuote(isSubmitted)

        const products = get(quote, 'products') && parseImages(get(quote, 'products')) || []
        const sortedProducts = products.sort((a, b) => a.position - b.position)
        const hasProducts = !isEmpty(sortedProducts)
        const productsSet = hasProducts ? sortedProducts : [{ key: 'product1' }]
        setProductsToSave(productsSet)
        setDisableSave(!hasProducts)
        setDisableSubmit(!hasProducts)
        setKey(Math.random())
      })
  }, [props.match.params])

  const parseImages = (products) => {
    return products.map(item => {
      let images = []
      if (item.images) {
        try {
          images = JSON.parse(item.images)
        } catch (error) {
          console.log('%c--Error parsing images--', 'color: #ff0000', error)
        }
      }
      return { ...item, images }
    })
  }

  const saveNotification = () => {
    notification.open({
      type: 'success',
      message: 'Saved',
    })
  }

  const onCancelQuote = async (id) => {
    const { quoteId } = props.match.params
    // TODO
    cancelQuote(quoteId)
  }

  const saveProduct = async () => {
    if (isEmpty(productsToSave)) {
      return
    }

    const { quoteId } = props.match.params

    const productsWithImagesStringifiedAndSellPrice = productsToSave.map(item => {
      const productKey = item.key
      const isActive = item.is_active
      const isDeleted = deletedProducts.indexOf(productKey) > -1
      const updatedIsActive = isDeleted ? false : isActive

      return {
        ...item,
        images: JSON.stringify(item.images),
        sell_price: item.fob_cost * (1 + DEFAULT_MACVAD_GP),
        is_active: updatedIsActive
      }
    })
    const response = await upsertProducts(quoteId, productsWithImagesStringifiedAndSellPrice)
    const updatedProducts = parseImages(response)
    setProductsToSave(updatedProducts)
    saveNotification()
    setKey(Math.random())
  }

  const openSubmitProduct = () => {
    if (isEmpty(productsToSave)) {
      return
    }

    validateCanSubmit(productsToSave)

    const message = validateProductFields()
    const hasError = message && message.find(error => !isEmpty(error.key))
    if (hasError) {
      return
    }

    openModal()
  }

  const validateProductFields = () => {
    setErrorMessage('')

    let hasRequiredFieldErrors = false
    let hasDuplicateItemNoErrors = false
    let hasErrors = false

    const requiredFieldsErrorMessages = productsToSave.map(product => {
      const columns = keys
      const errors = columns.reduce((current, column) => {
        if (column.key === 'id' || column.type === 'boolean') {
          return current
        }
        const columnValue = product && product[column.key]
        if (!columnValue && !column.optional) {
          const separator = current ? ',' : ''
          return `${current}${separator} ${column.title}`
        }

        return current
      }, '')

      if (errors) hasRequiredFieldErrors = true

      return errors
       ? (<Error key={product.key}> {product.key} - {errors} is required </Error>)
       : ('')
    })

    const duplicateItemNoErrorMessages = productsToSave.map((product, productIdx) => {
      if (product.is_active == false) { return '' }
      const matchingItemNo = productsToSave.find((item, itemIdx) =>
        item.item_no === product.item_no && productIdx !== itemIdx
      )

      if (matchingItemNo) hasDuplicateItemNoErrors = true

      return matchingItemNo
       ? (<Error key={product.key}>&nbsp;{product.key} - Item number: {product.item_no}. Every product must have a unique item number.&nbsp;</Error>)
       : ('')
    })

    hasErrors = hasRequiredFieldErrors || hasDuplicateItemNoErrors

    if (hasDuplicateItemNoErrors) {
      setErrorMessage(duplicateItemNoErrorMessages)
      return duplicateItemNoErrorMessages
    }

    setErrorMessage(requiredFieldsErrorMessages)
    return requiredFieldsErrorMessages
  }

  const cancelQuoteModal = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this quote?')
    if (confirmed) {
      await onCancelQuote(id)
      window.location.href = '/quotes'
    }
  }

  const validateCanSubmit = (productsToSave) => {
    setSubmitValidation(null)

    const allImages = productsToSave && productsToSave.map(item => JSON.stringify(item.images))
    const noImages = allImages.filter(image => !image || image === '[]')

    console.log(' *** YYY.000 validateCanSubmit ', {
      productsToSave, allImages, noImages
    })

    if (noImages && noImages.length > 0) {
      setSubmitValidation('Cannot submit quote - every product must have an image before submitting.')
      return false
    }

    return true
  }

  const submitProducts = async () => {
    if (isEmpty(productsToSave)) return
    if (!validateCanSubmit(productsToSave)) return

    const { quoteId } = props.match.params
    await saveProduct()
    await submitQuote(quoteId)
    setModalOpen(false)
    setIsSubmittedQuote(true)
    setKey(Math.random())
  }

  const addNewProductColumn = () => {
    const newProductSet = productsToSave.concat([{
      key: `product${productsToSave.length + 1}`,
      position: (productsToSave.length + 1) * 1000,
    }])

    setProductsToSave(newProductSet)
  }

  const showAddProductComponent = () => {
    if (isSubmittedQuote) {
      return
    }
    return (
      <div onClick={() => addNewProductColumn()}><i className="fas fa-plus pointer"></i></div>
    )
  }

  const updateProductField = async (key, propertiesToUpdate) => {
    const appendNewProduct = (newProduct) => productsToSave.concat([newProduct])

    const modifyExistingProduct = (updatedProduct) => {
      return productsToSave.map(product => product.key === updatedProduct.key ? updatedProduct : product)
    }

    const productToUpdate = productsToSave.find(product => product.key === key)
    const updatedProduct = {
      ...productToUpdate,
      ...propertiesToUpdate,
      key,
    }

    const modifiedProducts = productToUpdate ? modifyExistingProduct(updatedProduct) : appendNewProduct(updatedProduct)
    setProductsToSave(modifiedProducts)
    setKey(Math.random())
  }

  const handleImageUpload = (columnName) => async (e) => {
    const files = Array.from(e.target.files)
    const response = await Promise.all(files.map((file, i) => {
      const formData = new FormData()
      formData.append(i, file)
      return uploadImage(formData)
    }))

    const images = []
    response.forEach(item => {
      if (item) images.push(item.url)
    })

    const productToUpdate = productsToSave.find(item => item.key === columnName)
    const imagesToUpdate = productToUpdate.images
    const updatedImages = imagesToUpdate ? [...imagesToUpdate, ...images] : images
    const propertiesToUpdate = { images: updatedImages }

    await updateProductField(columnName, propertiesToUpdate)
    setSubmitValidation(null)
  }

  const handleImageDelete = (columnName) => async (url) => {
    const productToUpdate = productsToSave.find(item => item.key === columnName)
    const imagesToUpdate = productToUpdate.images
    const index = imagesToUpdate.indexOf(url)
    if (index > -1) {
      const updatedImages = [...imagesToUpdate.slice(0, index), ...imagesToUpdate.slice(index + 1)]
      const propertiesToUpdate = { images: updatedImages }
      await updateProductField(columnName, propertiesToUpdate)
    }
  }

  const handleBoolean = async (value, columnName, fieldName) => {
    const checked = value
    const propertiesToUpdate = {
      [fieldName]: checked
    }
    await updateProductField(columnName, propertiesToUpdate)
  }

  const handlePackagingSelect = async (value, columnName, fieldName) => {
    const propertiesToUpdate = {
      [fieldName]: value
    }

    await updateProductField(columnName, propertiesToUpdate)
  }

  const generateColumn = () => {
    const productFieldsColumn = [{
      title: '',
      dataIndex: 'columnName',
      key: 'columnName',
      render: (text) => {
        return (<b>{text}</b>)
      },
    }]

    const deleteProductColumn = (columnName, productKey) => {
      const confirmed = window.confirm('Are you sure you want to delete this product?')
      if (confirmed) {
        setDeletedProducts([...deletedProducts, productKey])
      }
    }

    const copyProductColumn = (columnName, productKey) => {
      const productToCopy = productsToSave.find(product => product.key === productKey)
      const updatedProductToSaveSet = productsToSave.concat([{
        ...productToCopy,
        key: `product${productsToSave.length + 1}`,
        position: (productsToSave.length + 1) * 1000,
        id: null,
      }])

      setProductsToSave(updatedProductToSaveSet)
      setKey(Math.random())
    }

    const dataColumnTitle = (text, columnName, product) => {
      if (isSubmittedQuote) {
        return text
      }
      return (
        <>
          {text}
          &nbsp;&nbsp;&nbsp;
          <CopyIconContainer onClick={() => copyProductColumn(columnName, product.key)}>
            <i className="fas fa-paste fa-2x"></i>&nbsp;Copy
          </CopyIconContainer>
          &nbsp;&nbsp;&nbsp;
          {
            productsToSave.length > 1 &&
            <DeleteIconContainer onClick={() => deleteProductColumn(columnName, product.key)}>
              <i className="fas fa-trash fa-2x"></i>&nbsp;Delete
            </DeleteIconContainer>
          }
        </>
      )
    }

    const filtered = productsToSave.filter(product => {
      const productKey = product && product.key
      const isDeleted = productKey && deletedProducts.indexOf(productKey) > -1
      if (productsToSave.length === 1) return true // cant filter out if there is only 1
      if (isDeleted) return false
      return product.is_active != false
    })

    const dataColumn = filtered
        .map((product, productIndex) => {
      const columnName = `product${productIndex+1}`
      const titleText = product.item_no ? `${product.item_no}: ` : `Product ${productIndex+1}: `
      const title = dataColumnTitle(titleText, columnName, product)
      const allKeys = keys

      return {
        title,
        dataIndex: columnName,
        key: columnName,
        editable: !isSubmittedQuote,
        width: '300px',
        render: (text, row) => {

          const fieldName = row.key
          if (row.type === 'images') {
            return (
              <ImageHandler
                images={row[columnName]}
                quoteSubmitted={isSubmittedQuote}
                onUpload={handleImageUpload(columnName)}
                onDelete={handleImageDelete(columnName)}
              />
            )
          }
          if (row.type === 'boolean') {
            return (
              <BooleanContainer
                quoteSubmitted={isSubmittedQuote}
                checked={row[columnName]}
                handleBoolean={handleBoolean}
                fieldName= {fieldName}
                columnName={columnName}
              />
            )
          }
          if (row.type === 'packagings') {
            return (
              <MultiSelectContainer
                defaultValue={text}
                quoteSubmitted={isSubmittedQuote}
                handleOnChange={handlePackagingSelect}
                fieldName= {fieldName}
                columnName={columnName}
                options={packagings}
                placeholder="Select Packaging Type"
              />
            )
          }
          if (row.key === 'fob_cost') {
            return (
              <FobCostContainer
                value={text}
                quoteSubmitted={isSubmittedQuote}
              />
            )
          }
          if (row.type === 'number') {
            return (
              <NumberContainer
                row={row}
                allKeys={allKeys}
                title={row.columnName}
                value={text}
                quoteSubmitted={isSubmittedQuote}
              />
            )
          }
          if (row.type === 'date' && text) {
            return <div id={`${columnName}${fieldName}`}>{moment(text).format('DD-MM-YYYY')}</div>
          }
          if (row.type === 'label') {
            return <div id={`${columnName}${fieldName}`}>{text}</div>
          }

          return <div id={`${columnName}${fieldName}`}>{text}</div>
        },
      }
    })

    const lastColumnIndex = productsToSave.length + 1
    const lastColumn = isSubmittedQuote
      ? []
      : [{
        title: showAddProductComponent(),
        dataIndex: `product${lastColumnIndex}`,
        key: `product${lastColumnIndex}`,
        width: '5%',
        align: 'center',
        render: (text) => {
          return text
        },
      }]

    return productFieldsColumn.concat(dataColumn).concat(lastColumn)
  }

  const formatHWD = (product, box) => {
    const l = get(product, `${box}_length`) || 0
    const h = get(product, `${box}_height`) || 0
    const w = get(product, `${box}_width`) || 0
    return `${h} x ${w} x ${l} cm`
  }

  const formatCbm = (product, box) => {
    const l = get(product, `${box}_length`) || 0
    const h = get(product, `${box}_height`) || 0
    const w = get(product, `${box}_width`) || 0
    const cbm = (l * h * w) / 1000000
    return `${cbm.toFixed(4)} m³`
  }

  const generateDataSource = () => {
    let rows = []
    const allProductColumns = keys
    allProductColumns.forEach(({ key, title, type, optional }, columnIndex) => {
      const item = {
        key,
        type,
        columnName: title,
        nextRow: allProductColumns[columnIndex+1],
      }

      const filtered = productsToSave.filter(product => {
        const productKey = product && product.key
        const isDeleted = productKey && deletedProducts.indexOf(productKey) > -1
        if (productsToSave.length === 1) return true // cant filter out if there is only 1
        if (isDeleted) return false
        return product.is_active != false
      })

      const row = filtered
        .reduce((current, product, productIndex) => {
        let data = get(product, key)

        const productKey = product && product.key

        // YYYY HERE - set default position?
        if (key === 'position' && !data) {
          data = (productIndex + 1) * 1000
        }
        // DONE

        // YYYY HERE - set default is_active?
        if (key === 'is_active' && !data) {
          data = true
        }
        // DONE

        // if product is marked as deleted then set is_active = false too
        if (key === 'is_active') {
          const isDeleted = deletedProducts.indexOf(productKey) > -1
          if (isDeleted) {
            data = false
          }
        }

        if (key === 'inner_dimensions') {
          data = formatHWD(product, 'inner')
        } else if (key === 'outer_dimensions') {
          data = formatHWD(product, 'outer')
        } else if (key === 'outer_cbm') {
          data = formatCbm(product, 'outer')
        }

        return {
          ...current,
          [`product${productIndex+1}`]: data,
        }
      }, item)

      rows = rows.concat(row)
    })

    return rows
  }
  
  const transformDataForSave = (dataSource = []) => {
    if (isSubmittedQuote) {
      return
    }
    setErrorMessage('')
    const mappedData = dataSource.reduce((current, row) => {
      const productColumns = Object.keys(row).filter(k => k !== 'columnName' && k !==  'key' && k !== 'type' && k !== 'nextRow')
      const productsData = productColumns.reduce((acc, product) => {
        let value = row[product] ? row[product] : (row.type === 'boolean' ? false : null)
        const item = [{
          key: product,
          [row['key']]: value
        }]

        return [...acc, ...item]
      }, [])

      return [...current, ...productsData]
    }, [])

    const products = chain(mappedData)
      .groupBy('key')
      .map((value, key) => {
        return value.reduce((acc, field) => {
          return {...acc, ...field}
        }, {})
      })
      .value()

    setDisableSave(!(products && products.length > 0))
    setDisableSubmit(!(products && products.length > 0))
    setProductsToSave(products)
  }
  
  const modal = () => (
    <Modal
      visible={modalOpen}
      title={false}
      footer={false}
      closable={false}
      width="40.3125rem"
      onCancel={closeModal}
    >
      <h4>
        Submit Quote
      </h4>
      <Divider/>
      <Row>
        <ModalText>Once the quote is submitted, no further changes will be allowed.</ModalText>
      </Row>
      {
        submitValidation &&
        <Row>
          <ModalText>
            <Error>{submitValidation}</Error>
          </ModalText>
        </Row>
      }
      <ModalBottom>
        <input onClick={closeModal} className="btn btn-outline-success" type="button" value="Cancel" />
        {
          !submitValidation &&
          <input onClick={submitProducts} className="btn btn-outline-success" disabled={disableSubmit} type="button" value="Submit quote" />
        }
      </ModalBottom>
    </Modal>
  )

  const handleExcelImport = (data) => {
    setStep('addProducts')
    const headerlessData = data.slice(3, 203)
    const products = headerlessData.filter(row => {
      let isSpecified = false 
      for (let i = 0; i < 26; i++) {
        if (i > 0 && !!row[i]) {
          isSpecified = true
          break
        }
      }
      return isSpecified
    })

    const specifiedProducts = productsToSave.filter(item => {
      let isSpecified = false
      for (let propKey in item) {
        if (!['id', 'key', 'outer_dimensions'].includes(propKey) && item[propKey]) {
          isSpecified = true
          break
        }
      }
      return isSpecified
    })
    const existingProductCount = specifiedProducts.length
    const updatedProductCollection = [...specifiedProducts]
    products.forEach((item, index) => {
      const product = {
        key: `product${existingProductCount + index + 1}`,
        position: (existingProductCount + index + 1) * 1000,
        item_no: item[2],
        description: item[1],
        size: item[3],
        colour: item[4],
        features: item[5],
        packaging_type: item[6],
        moq: item[7],
        material: item[8],
        fob_cost: item[9],
        units_per_outer: item[10],
        units_per_inner: item[11],
        outer_cbm: item[19],
        batteries: item[16] === BATTERIES_REQUIREMENT.REQUIRED_AND_INCLUDED,
        assembled: item[17] === PRODUCT_SHIP_MANNER.FULLY_ASSEMBLED,
        screws: item[18],
        outer_length: item[12],
        outer_width: item[13],
        outer_height: item[14],
        weight_kg: item[15],
        qty_per_20: item[20],
        qty_per_40: item[21],
        qty_per_40_hq: item[22],
        outer_dimensions: `${item[14]} x ${item[13]} x ${item[12]} cm`,
        inner_length: item[23],
        inner_width: item[24],
        inner_height: item[25],
        is_active: true,
      }
      updatedProductCollection.push(product)
    })

    // remove empty rows - excel sheet might have included rows with no product data
    const filtered = updatedProductCollection.filter(product => product.item_no && product.item_no !== '')

    setProductsToSave(filtered) //(updatedProductCollection)
    setKey(Math.random())
  }

  const handleTemplateDownload = () => {
    setStep('importTemplate')
    exportTemplate(packagings)
  }

  const handleStepSkip = () => {
    setStep('addProducts')
  }

  const renderButtonGroup = () => {
    return (
      <ButtonContainer>
        {!isSubmittedQuote && (
          <React.Fragment>
            <Button
              value={'Save'}
              disabled={disableSave}
              onClick={saveProduct}
            />
            <Button
              value={'Submit Quote'}
              disabled={disableSubmit}
              onClick={openSubmitProduct}
            />
            <Button
              value={'Delete Quote'}
              onClick={cancelQuoteModal}
            />
          </React.Fragment>
        )}
        <Button
          value="Close"
          onClick={() => window.location.href = '/quotes'}
        />
      </ButtonContainer>
    )
  }

  const renderDownloadTemplateInstructions = () => {
    return (
      <div className={'instructions'} style={{ width: '50%' }}>
        <div className={'flexrow'} >
          <div className={'flexcolumn'}>
            <span className={'step'}>Step 2</span><br />
            TEMPLATE  <br />
            Download the Excel template <br />
            Complete the mandatory fields for each product. Save the file on your PC   <br />
            1 Row per product  <br />
          </div>
          <div className={'flexcolumn'}>
            <span className={'step'}>步骤二 </span><br />
            模板 <br />
            下载 Excel 模板 <br />
            填写每个产品的指定单元格后将文件保存在您的电脑上。 <br />
            请注意：每个产品一行；绿色标题为必填项。 <br />
          </div>
        </div>
      </div>
    )
  }

  const renderImportTemplateInstructions = () => {
    return (
      <div className={'instructions'} style={{ width: '50%' }}>
        <div className={'flexrow'} >
          <div className={'flexcolumn'}>
            <span className={'step'}>Step 3</span><br />
            IMPORT  <br />
            Select the file on your PC and open <br />
          </div>
          <div className={'flexcolumn'}>
            <span className={'step'}>步骤三 </span><br />
            导入 <br />
            点击下方按键后从您的电脑上选择文件并打开。 <br />
          </div>
        </div>
      </div>
    )
  }

  const renderDownloadButton = () => {
    return (
      <Frame>
        <Button
          value="Download Excel Template"
          onClick={handleTemplateDownload}
        />
        <Button
          value='Skip and Load Manually'
          onClick={handleStepSkip}
        />
      </Frame>
    )
  }

  const renderDownloadStep = () => {
    return (
      <React.Fragment>
        <br />
        {renderDownloadTemplateInstructions()}
        <br />
        {renderDownloadButton()}
        <br />
      </React.Fragment>
    )
  }

  const renderImportButton = () => {
    return (
      <Frame>
        <ExcelParser
          title='Import Products'
          onExcelImport={handleExcelImport}
        />
        <Button
          value='Skip and Load Manually'
          onClick={handleStepSkip}
        />
      </Frame>
    )
  }

  const renderImportStep = () => {
    return (
      <React.Fragment>
        <br />
        {renderImportTemplateInstructions()}
        <br />
        {renderImportButton()}
      </React.Fragment>
    )
  }

  const renderAddProductsInstructions = () => {
    return (
      <div className={'instructions'} style={{ width: '70%' }}>
        <div className={'flexrow'} >
          <div className={'flexcolumn'}>
            <span className={'step'}>Step 4</span><br />
              Manually enter any extra fields and add photo(s) for each product   <br />
              <br />
              <span className={'step'}>Step 5</span><br />
              SAVE		(if you wish to leave and return to edit later)   <br />
              SUBMIT	(This locks and submits the pricing to the MACVAD Trading team) <br />
            </div>
          <div className={'flexcolumn'}>
            <span className={'step'}>步骤四</span><br />
            导入文件后若网页上有额外字段请手动填写并为每个产品添加照片。<br />
            <br />
            <span className={'step'}>步骤五</span><br />
            保存 (若您想离开并返回后继续编辑）<br />
            提交（价格会被锁定并提交给 MACVAD 交易团队）<br />
          </div>
        </div>
      </div>
    )
  }

  const renderAddProductsStep = () => {

  // check for duplicate item numbers
  const duplicateItemNumbers = []
  productsToSave.forEach((product, productIdx) => {
    if (product.is_active != false) {
      const matchingItemNo = productsToSave.find((item, itemIdx) =>
        item.item_no === product.item_no && productIdx !== itemIdx
      )
      if (matchingItemNo) {
        const existingWarning = duplicateItemNumbers.indexOf(product.item_no) > -1
        if (!existingWarning) {
          duplicateItemNumbers.push(product.item_no)
        }
      }
    }
  })

  const hasDuplicates = duplicateItemNumbers.length > 0

    return (
      <React.Fragment>
        <br />
        {renderAddProductsInstructions()}
        <br />
        <div className={'flexContainer'}>
          <div className={'flexColumn'}>
            <h5>Supplier: <span className={'content'}>{get(quote, 'supplier.legal_name')}</span></h5>
            <h5>Range or Quote Name: <span className={'content'}>{quote && quote.name} (v{quote && quote.version_number}) #{quote && quote.quote_number}/{quote && quote.version_number}</span></h5>
            <h5>FOB Export Port: <span className={'content'}>{quote && quote.port && quote.port.name}</span></h5>
            <h5>Terms: <span className={'content'}>{quote && quote.payment_term && quote.payment_term.name}</span></h5>
          </div>
          <div className={'flexColumn'}>
            <h5>Date Added: <span className={'content'}>{quote && moment(quote.created_at).format('DD MMM YYYY')}</span></h5>
            <h5>Valid Until: <span className={'content'}>{quote && quote.valid_until && moment(quote.valid_until).format('DD MMM YYYY')}</span></h5>
            <h5>Created By: <span className={'content'}>{(quote && quote.user && quote.user.full_name) || '-'}</span></h5>
          </div>
        </div>
        <br />
        {
          hasDuplicates &&
          <React.Fragment>
            <div style={{ border: '2px solid red', borderRadius: '5px', backgroundColor:'pink', padding: '10px' }}>
              ERROR - Product item numbers must be unique. These item numbers have been repeated: {duplicateItemNumbers.join(', ')}
            </div>
            <br />
          </React.Fragment>
        }
        { errorMessage && <ErrorContainer>{errorMessage}</ErrorContainer> }
        {renderButtonGroup()}
        <ProductEditableTable
          key={key}
          isReadonly={isSubmittedQuote}
          columns={generateColumn()}
          dataSource={generateDataSource()}
          transformDataForSave={transformDataForSave}
        />
        <div className="line"></div>
        {renderButtonGroup()}
        {modal()}
      </React.Fragment>
    )
  }

  const renderUploadHeader = () => {
    if (quote) {
      return (
        <h2>Upload Products - {quote && quote.name} (v{quote && quote.version_number})</h2>
      )
    }

    return (
      <h2>Upload Products</h2>
    )
  }

  const enteredProducts = productsToSave && productsToSave.filter(item => item.item_no)
  const productCount = enteredProducts ? enteredProducts.length : 0

  return (
    <React.Fragment>
      <div>
        <br />
        {renderUploadHeader()}
        {productCount === 0 && step === 'downloadTemplate' && renderDownloadStep()}
        {productCount === 0 && step === 'importTemplate' && renderImportStep()}
        {(productCount > 0 || step === 'addProducts') && renderAddProductsStep()}
        <br />
      </div>
    </React.Fragment>
  )
}

const ModalText = styled.span`
  display: inline-block;
  color: gray;
  font-size: 16px;
  padding-bottom: 20px;
  color: rgba(0, 0, 0, 0.65);
  width: 100%;
`

const Divider = styled.div`
  background-color: #e9ecf6;
  height: 1px;
  margin-bottom: 15px;
`

const Row = styled.div`
  display: block;
  padding: 5px;
`

const ModalBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * + * {
    margin-left: 0.5em;
  }
`
const Frame = styled.div`
  padding: 20px;
  width: 90%;
  border: 1px solid lightgray;
  border-radius: 3px;
  display: flex;
`

const ButtonContainer = styled.div`
  margin: 10px 0;
  display: flex;
`

const Button = styled.input.attrs(() => ({
  className: 'btn btn-outline-success',
  type: 'button',
}))`
  margin-left: 10px;
`

const Error = styled.span`
  display: inline-block;
  font-size: 14px;
  color: #f5222d;
  padding: 8px 0px 0px 0px;
  
  &:first-letter {
    text-transform: capitalize
  }
`

const ErrorContainer = styled.div`
  margin-bottom: 20px;
`

const CopyIconContainer = styled.span`
  cursor: pointer;
  font-size:0.8em;
  font-weight: normal;
`

const DeleteIconContainer = styled.span`
  cursor: pointer;
  font-size:0.8em;
  font-weight: normal;
`

function mapStateToProps(state) {
  return {
    quote: state.selectedQuote,
    products: state.products,
    packagings: state.packagings,
  }
}

export default connect(mapStateToProps)(AddProductWizard)
