import React from 'react'
import { Carousel, Icon } from 'antd'
import styled from 'styled-components'
import 'antd/dist/antd.css'

import './Carousel.css'

const SlideContainer = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SlideContent = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const Arrow = ({ type, style, className, onClick }) => {
  return (
    <Icon
      type={type}
      style={style}
      className={className}
      onClick={onClick}
    />
  )
}

const Slide = ({ image }) => {
  return (
    <SlideContainer>
      <SlideContent src={image} alt='No Image' />
    </SlideContainer>
  )
}

const ImageCarousel = ({ images, onChange }) => {
  const handleChange = (currentIndex) => {
    if (onChange) {
      onChange(currentIndex)
    }
  }

  return (
    <Carousel
      arrows
      dots={false}
      prevArrow={<Arrow type='left' />}
      nextArrow={<Arrow type='right' />}
      afterChange={handleChange}
    >
      {images.map((image, index) => {
        return <Slide key={index} image={image} />
      })}
    </Carousel>
  )
}

export default ImageCarousel
