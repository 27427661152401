import React, { Component } from 'react'
import { Table, Input, Form } from 'antd';
import styled from 'styled-components';
import { get } from 'lodash'

const EditableContext = React.createContext();

// ['position', 'is_active']

const EditableRow = ({ form, index, ...props }) => {
  return (
    <EditableContext.Provider value={form}>
      {['position', 'is_active'].indexOf(props['data-row-key']) > -1 ? null : <tr {...props} />}
    </EditableContext.Provider>
  )

  // return (
  //   <EditableContext.Provider value={form}>
  //     {props['data-row-key'] === 'position' ? null : <tr {...props} />}
  //   </EditableContext.Provider>
  // )
};

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  handleSave = e => {
    const { record, onSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      onSave({ ...record, ...values });
    });
  };

  handleTabKeyPress = e => {
    e.preventDefault();
    const { dataIndex, record } = this.props;
    const lastCharacter = dataIndex.charAt(dataIndex.length - 1);
    const nextProduct = `product${Number(lastCharacter)+1}`;

    const nextId = `${nextProduct}${record.key}`;
    const nextElement = document.getElementById(nextId);
    if (nextElement) {
      if (record.type === 'boolean') {
        return nextElement.focus();
      }
      else {
        return nextElement.click();
      }
    }

    const nextRowKey = get(record, 'nextRow.key')
    const nextRowFirstElementId = `product1${nextRowKey}`;
    const nextRowFirstElement = document.getElementById(nextRowFirstElementId);
    if (!nextRowFirstElement) {
      return
    }
    const nextRowTypeElementType = get(record, 'nextRow.type')
    if (nextRowTypeElementType === 'boolean') {
      return nextRowFirstElement.focus();
    }
    else {
      return nextRowFirstElement.click();
    }
  }

  handleKeyPress = e => {
    if (e.key === "Tab") {
      return this.handleTabKeyPress(e)
    }
    if (e.key === "Enter") {
      return this.handleEnterPress(e)
    }
  }

  handleEnterPress = e => {
    e.preventDefault();
    const { dataIndex, record } = this.props;
    const nextRowKey = get(record, 'nextRow.key')
    const nextId = `${dataIndex}${nextRowKey}`;
    const nextElement = document.getElementById(nextId);
    if (!nextElement) {
      return
    }

    const nextRowType = get(record, 'nextRow.type')
    if (nextRowType === 'boolean') {
      return nextElement.focus();
    }
    else {
      return nextElement.click();
    }
  }

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record } = this.props;
    const { editing } = this.state;
    const type = (record && record.type) || 'text';

    //console.log(' *** YYY.100 editable renderCell', { dataIndex, record, editing, children })

    // if (record.readOnly || record.key === 'outer_cbm') {
    //   return (
    //     <div className="editable-cell-value-wrap" style={{ border: '1px solid darkgray !important '}}>
    //       {children}
    //     </div>
    //   )
    // }

    if (record.type === 'images'
      || record.type === 'boolean'
      || record.type === 'packagings') {
      return (
        <div className="photo-cell" onKeyDown={this.handleKeyPress}>
          {children}
        </div>
      )
    }

    if (record.type === 'label') {
      return (
        <div className="label-cell" onKeyDown={this.handleKeyPress}>
          {children}
        </div>
      )
    }

    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, { initialValue: record[dataIndex] })(
          <Input
            type={type}
            ref={node => (this.input = node)}
            onKeyDown={this.handleKeyPress}
            onBlur={this.handleSave}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      onSave,
      children,
      ...restProps
    } = this.props;

    return (
      <td {...restProps}>
        {(editable) ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

export class ProductEditableTable extends React.Component {
  constructor(props) {
    super(props);
    const { dataSource, transformDataForSave } = props;

    transformDataForSave(dataSource);
  }

  handleSave = row => {
    const { dataSource, transformDataForSave } = this.props

    const newData = [...dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    transformDataForSave(newData);
  };

  render() {
    const { dataSource, columns } = this.props

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const allColumns = columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          onSave: this.handleSave,
        }),
      };
    });

    const getWrapperClass = (isReadonly, columnsCount) => {
      const count = isReadonly ? columnsCount - 1 : columnsCount - 2
      return isReadonly ? `table-wrapper-${count}` : `table-wrapper-${count}`;
    }
    
    return (
      <div className={allColumns.length > 12 ? 'table-wrapper' : getWrapperClass(this.props.isReadonly, allColumns.length)}>
        <StyledTable
          id={'addProductsTable'}
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={allColumns}
          pagination={false}
        />
      </div>
    );
  }
}

const StyledTable = styled(Table)`
  & tbody > tr:first-child > td {
    display: none;
  }
`;
