import store from '../redux/store'
import { quotesFetched, quoteCreated, quoteCopied, quoteCategoryUpdated, quoteCancelled } from '../redux/actions/quotes'
import { quoteFetched, quoteSubmitted } from '../redux/actions/selected-quote'

import { QUOTES_PATH } from '../config/urls'
import { getData, postData } from './simple-request'
import { orderBy } from 'lodash'

export async function fetchQuotes() {
  const url = QUOTES_PATH
  const quotes = await getData(url);

  if (quotes) {
    store.dispatch(quotesFetched(orderBy(quotes, ['created_at'])))
  }
  return quotes
}

export async function createQuote(data) {
  const url = QUOTES_PATH
  const quote = await postData(url, data);

  if (quote) {
    store.dispatch(quoteCreated(quote));
  }
  return quote
}

export async function fetchQuote(quoteId) {
  const url = `${QUOTES_PATH}/${quoteId}`
  const quote = await getData(url);

  if (quote) {
    store.dispatch(quoteFetched(quote));
  }
  return quote
}

export async function submitQuote(quoteId) {
  const url = `${QUOTES_PATH}/submit/${quoteId}`
  const quote = await postData(url);

  if (quote) {
    store.dispatch(quoteSubmitted(quote));
  }
  return quote
}

export async function copyQuote(quoteId, name, portId) {
  const url = `${QUOTES_PATH}/copy/${quoteId}`
  const quote = await postData(url, {name, portId});

  if (quote) {
    store.dispatch(quoteCopied(quote));
  }
  return quote
}

export async function cancelQuote(quoteId) {
  const url = `${QUOTES_PATH}/cancel/${quoteId}`
  const quote = await postData(url, {});

  if (quote) {
    store.dispatch(quoteCancelled(quote));
  }
  return quote
}

export async function updateQuoteCategory(quoteId, categoryId) {
  const url = `${QUOTES_PATH}/update_category/${quoteId}`
  const quote = await postData(url, { categoryId });

  if (quote) {
    store.dispatch(quoteCategoryUpdated(quote));
  }
  return quote
}
