import store from '../redux/store'
import { paymentTermsFetched } from '../redux/actions/payment-terms'
import { PAYMENT_TERMS_PATH } from '../config/urls'
import { getData } from './simple-request'
import { orderBy } from 'lodash'

export async function fetchPaymentTerms() {
  const url = PAYMENT_TERMS_PATH
  const terms = await getData(url);

  if (terms) {
    store.dispatch(paymentTermsFetched(orderBy(terms, ['sort_order'])))
  }

  return terms
}