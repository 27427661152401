import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import './App.css'
import QuoteList from './components/Quotes/QuoteList'
//import AddProduct from './components/Product/AddProduct'
import AddProductWizard from './components/AddProductWizard/AddProductWizard'
import AddQuoteWizard from './components/AddQuoteWizard/AddQuoteWizard'
import SupplierLogin from './components/SupplierLogin/SupplierLogin'
import NotFound from './components/NotFound/NotFound'
import { isEmpty } from 'lodash'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import AcceptInvitation from './components/AcceptInvitation/AcceptInvitation'
import UserName from './components/User/UserName'

function App() {
  const { Header, Content } = Layout
  const logout = () => {
    localStorage.removeItem('accessToken')
    window.location.href = '/login'
  }
  const isSupplierRequireLogin = () => {
    const accessToken = localStorage.getItem('accessToken')
    return isEmpty(accessToken)
  }

  if (isSupplierRequireLogin()) {
    return (
        <Router>
          <div className="wrapper">
            <Layout className="layout">
              <Header>
                <div className="logo">
                  <h3>
                    MACVAD
                    <span> Supplier Prices Portal</span>
                  </h3>
                </div>
              </Header>
              <Content style={{ padding: '0 50px' }}>
                <Switch>
                  <Route exact path="/login" component={SupplierLogin} />
                  <Route exact path="/" component={SupplierLogin} />
                  <Route exact path="/acceptinvitation/:code" component={AcceptInvitation} />
                  <Route path="/404" component={NotFound} />
                  <Redirect to="/login" />
                </Switch>
              </Content>
            </Layout>
          </div>
        </Router>
    )
  }

  return (
    <Router>
      <div className="wrapper">
        <Layout className="layout">
          <Header>
            <div className="logo">
              <h3>
                MACVAD
                <span> Supplier Prices Portal</span>
              </h3>
            </div>
            <Menu theme="dark" mode="horizontal" style={{ lineHeight: '64px' }}>
              <Menu.Item className='logout' key="logout"><Link to="#" onClick={logout}>Logout</Link></Menu.Item>
              <Menu.Item className='username' key="username"><UserName /></Menu.Item>
              <Menu.Item className='quotes' key="quotes"><Link to="/quotes">Quotes</Link></Menu.Item>
            </Menu>
          </Header>
          <Content style={{ padding: '0 50px' }}>
            <Switch>
              <Route exact path="/" component={QuoteList} />
              <Route exact path="/quote" component={AddQuoteWizard} />
              <Route exact path="/quotes" component={QuoteList} />
              <Route exact path="/products/:quoteId" component={AddProductWizard} />
              <Route path="/404" component={NotFound} />
              <Redirect to="/404" />
            </Switch>
          </Content>
        </Layout>
      </div>
    </Router>
  )
}

export default App;
