import * as actions from '../actions/ports'

export default function(state = [], action) {
  switch (action.type) {
    case actions.PORTS_FETCHED: 
      return action.payload || state
    default:
      return state;
  }
}
