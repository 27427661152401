import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  color: red;
  font-size: x-large;
`

const Deleter = ({ onDelete }) => {
  const handleDelete = () => {
    const confirmed = window.confirm('Are you sure you want to delete this image?')
    if (confirmed && onDelete) {
      onDelete()
    }
  }
  return (
    <Container onClick={handleDelete}>
      <i className="fas fa-trash-alt pointer"></i>
    </Container>
  )
}

export default Deleter
