export default function responseInterceptor(interceptors, response) {
  return runThroughNextInterceptor(0, interceptors, response)
}

function runThroughNextInterceptor(index, interceptors, response) {
  return Promise.resolve(response).then((data) => {
    if (index >= interceptors.length) {
      return data
    }
    return interceptors[index](data, runThroughNextInterceptor.bind(null, index + 1, interceptors))
  })
}
