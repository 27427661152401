import * as actions from '../actions/products'

export default function(state = [], action) {
  switch (action.type) {
    case actions.PRODUCTS_FETCHED: 
      return action.payload || state
    case actions.PRODUCTS_UPSERTED: 
      return action.payload || state
    default:
      return state;
  }
}
