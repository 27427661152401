import * as actions from '../actions/quotes'
import { get } from 'lodash'

export default function(state = [], action) {
  switch (action.type) {
    case actions.QUOTES_FETCHED: 
      return action.payload || state
    case actions.QUOTE_CREATED: 
      {
        const payload = get(action, 'payload') || []
        return state.concat([payload])
      }
    case actions.QUOTE_COPIED: 
      {
        const payload = get(action, 'payload') || []
        return state.concat([payload])
      }
    default:
      return state;
  }
}
