export const QUOTES_FETCHED = "QUOTES_FETCHED"
export const QUOTE_CREATED = "QUOTE_CREATED"
export const QUOTE_COPIED = "QUOTE_COPIED"
export const QUOTE_CANCELLED = "QUOTE_CANCELLED"
export const QUOTE_CATEGORY_UPDATED = "QUOTE_CATEGORY_UPDATED"

export const quotesFetched = (quotes) => ({
  type: QUOTES_FETCHED,
  payload: quotes,
})

export const quoteCreated = (quote) => ({
  type: QUOTE_CREATED,
  payload: quote,
})

export const quoteCopied = (quote) => ({
  type: QUOTE_COPIED,
  payload: quote,
})

export const quoteCancelled = (quote) => ({
  type: QUOTE_CANCELLED,
  payload: quote,
})

export const quoteCategoryUpdated = (quote) => ({
  type: QUOTE_CATEGORY_UPDATED,
  payload: quote,
})
