import React from 'react'
import uuid from 'uuid'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Label = styled.label`
  color: ${props => props.hasImages ? '#007FFF' : 'inherit'};
  font-size: ${props => props.hasImages ? '26px' : '50px'};
`

const Input = styled.input`
  display: none;
`

const Uploader = ({ hasImages, onUpload }) => {
  const id = uuid.v4()
  return (
    <Container>
      <Label htmlFor={id} hasImages={hasImages}>
        <i className={`${hasImages ? 'fas fa-plus-square' : 'fas fa-camera'} pointer`}></i>
      </Label>
      <Input
        id={id}
        type='file'
        multiple
        accept='image/*'
        onChange={onUpload}
      />
    </Container>
  )
}

export default Uploader
