import React from 'react'
import { Checkbox, Switch } from 'antd'

export default props => {
  if (props.quoteSubmitted) {
    return (<Checkbox disabled checked={props.checked} />)
  }

  return (<Switch
      id={`${props.columnName}${props.fieldName}`}
      checked={props.checked} 
      onChange={e => props.handleBoolean(e, props.columnName, props.fieldName)} />)
}

