import handleResponseError from './middleware/handleResponseError'
import responseInterceptor from './middleware/responseInterceptor'
import parseResponseToJson from './middleware/parseResponseToJson'
// import handleRenewToken from './middleware/handleRenewToken'

import { retry } from 'async'

export default class Request {

  constructor(path, options = {}) {
    this._method = options.method ? options.method : 'GET'
    this._path = path
    this._authHeaders = options.authHeaders
    this._headers = options.headers
    this._token = localStorage.accessToken
    this._retryAmount = 1 // options.retryAmount ? options.retryAmount : 3
    this._retryInterval = options.retryInterval ? options.retryInterval : 2000
    if (this._authHeaders) {
      this._headers = {
        ...this._headers,
        Authorization: this._token,
      }
    }
    this._interceptors = [handleResponseError, parseResponseToJson]
    this.body = undefined // Set to undefined always.
    if (options.body) { // If it had a body. Check what type and proceed correctly.
      if (options.body === null) {
        this._body = undefined
      } else if (options.body.constructor.name === 'FormData') {
        this._body = options.body
      } else {
        this._body = JSON.stringify(options.body)
      }
    }
  }

  setInterceptors(interceptors) {
    this._interceptors = interceptors
    return this
  }

  updateAuthHeaders() {
    this._headers = {
      ...this._headers,
      Authorization: `${localStorage.accessToken}`,
    }
    return this
  }

  fetchRequest(callback) {
    fetch(this._path, {
      method: this._method,
      headers: Object.assign({}, this._headers),
      body: this._body,
    })
    .then((result) => {
      if (result.status !== 401 && (result.status < 200 || result.status >= 300)) {
        // we need to retry
        callback(result)
      } else {
        // successful!
        callback(null, result)
      }
    }, (error) => {
      callback(error)
    })
  }

  perform() {
    return new Promise((resolve, reject) => {
      retry(
        { times: this._retryAmount, interval: this._retryInterval },
        this.fetchRequest.bind(this),
        (err, result) => {
          if (!err) {
            const _result = result
            _result.request = this
            resolve(_result)
          }
          reject(err)
        }
      )
    })
    .then((result) => {
      return responseInterceptor(this._interceptors, result)
    }, (error) => {
      return responseInterceptor(this._interceptors, error)
    })
  }
}
